import React, { useState, useEffect, useMemo } from 'react';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get } from 'firebase/database';
import logo from '../assets/logo_univ.png';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { useWorkshopData } from '../context/WorkshopContext';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const firebaseConfig = {
  apiKey: "AIzaSyBX95ZWzS-1-Oyifqv_AdWByr5Fw98nz6M",
  authDomain: "iot-nodemcu-c377b.firebaseapp.com",
  databaseURL: "https://iot-nodemcu-c377b-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "iot-nodemcu-c377b",
  storageBucket: "iot-nodemcu-c377b.appspot.com",
  messagingSenderId: "694620983513",
  appId: "1:694620983513:web:126eb90387f8b0174faec8"
};

const app = initializeApp(firebaseConfig);

const WORKSHOP_IDS = {
  workshop1: 'Node1',
  workshop2: 'Node2',
  workshop3: 'Node3',
  workshop4: 'Node4'
};

const AIR_QUALITY_THRESHOLDS = {
  pm10: {
    dangerous: 300,
    veryUnhealthy: 200,
    unhealthy: 150,
    moderate: 100,
    good: 0
  },
  pm2_5: {
    dangerous: 250,
    veryUnhealthy: 150,
    unhealthy: 100,
    moderate: 50,
    good: 0
  },
  oxygen: {
    dangerous: { min: 0, max: 19.5 },
    unhealthy: { min: 19.5, max: 20.5 },
    good: { min: 20.5, max: 21.5 },
    high: { min: 21.5, max: 100 }
  },
  tvoc: {
    dangerous: 2200,
    veryUnhealthy: 660,
    unhealthy: 220,
    moderate: 110,
    good: 0
  },
  co2: {
    dangerous: 5000,
    veryUnhealthy: 2000,
    unhealthy: 1000,
    moderate: 600,
    good: 0
  },
  hcho: {
    dangerous: 1,
    veryUnhealthy: 0.5,
    unhealthy: 0.1,
    moderate: 0.05,
    good: 0
  }
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#1e2130',
    color: 'white',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#383c54',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    marginBottom: '20px',
  },
  logo: {
    width: '50px',
    height: '50px',
    marginRight: '15px',
  },
  title: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    flex: '1',
  },
  clock: {
    border: '2px solid white',
    padding: '10px',
    borderRadius: '5px',
    textAlign: 'center',
    lineHeight: '1.5',
    minWidth: '200px',
  },
  graphContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  graphRow: {
    display: 'flex',
    marginBottom: '20px',
  },
  workshopGraph: {
    flex: 1,
    border: '2px solid #ddd',
    padding: '15px',
    borderRadius: '10px',
    margin: '10px',
  },
  graphTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  graphSubtitle: {
    textAlign: 'center',
    marginBottom: '15px',
    fontSize: '16px',
  },
  chartContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

// Komponen Clock terpisah
const Clock = () => {
  const [time, setTime] = useState(new Date());
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    // Update blink setiap detik
    const blinkTimer = setInterval(() => {
      setBlink(prev => !prev);
    }, 1000);

    // Update waktu setiap menit
    const timeTimer = setInterval(() => {
      setTime(new Date());
    }, 60000);

    return () => {
      clearInterval(blinkTimer);
      clearInterval(timeTimer);
    };
  }, []);

  return (
    <div style={styles.clock}>
      <div>
        Kendal, {time.toLocaleDateString('id-ID', { year: 'numeric', month: 'long', day: 'numeric' })}
      </div>
      <div style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
        {`${time.getHours().toString().padStart(2, '0')}${blink ? ':' : ' '}${time.getMinutes().toString().padStart(2, '0')}`} WIB
      </div>
    </div>
  );
};

const CategoryIndicators = () => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '200px',
    marginRight: '20px',
  }}>
    <div style={{ backgroundColor: '#000000', padding: '5px', margin: '2px', color: 'white', borderRadius: '5px', fontSize: '12px' }}>Berbahaya</div>
    <div style={{ backgroundColor: '#FF0000', padding: '5px', margin: '2px', color: 'white', borderRadius: '5px', fontSize: '12px' }}>Sangat Tidak Sehat</div>
    <div style={{ backgroundColor: '#FFA500', padding: '5px', margin: '2px', color: 'black', borderRadius: '5px', fontSize: '12px' }}>Tidak Sehat</div>
    <div style={{ backgroundColor: '#FFFF00', padding: '5px', margin: '2px', color: 'black', borderRadius: '5px', fontSize: '12px' }}>Sedang</div>
    <div style={{ backgroundColor: '#00FF00', padding: '5px', margin: '2px', color: 'black', borderRadius: '5px', fontSize: '12px' }}>Baik</div>
  </div>
);

const determineCategory = (value, type) => {
  const thresholds = AIR_QUALITY_THRESHOLDS[type];
  
  if (type === 'oxygen') {
    if (value < thresholds.dangerous.max) return { category: "Berbahaya - Oksigen Rendah", color: "#000000" };
    if (value < thresholds.unhealthy.max) return { category: "Tidak Sehat", color: "#FFA500" };
    if (value < thresholds.good.max) return { category: "Baik", color: "#00FF00" };
    return { category: "Tinggi", color: "#FF0000" };
  }
  
  if (value >= thresholds.dangerous) return { category: "Berbahaya", color: "#000000" };
  if (value >= thresholds.veryUnhealthy) return { category: "Sangat Tidak Sehat", color: "#FF0000" };
  if (value >= thresholds.unhealthy) return { category: "Tidak Sehat", color: "#FFA500" };
  if (value >= thresholds.moderate) return { category: "Sedang", color: "#FFFF00" };
  return { category: "Baik", color: "#00FF00" };
};

const generateBarData = (latestReading) => ({
  labels: ['PM10', 'PM2.5', 'O2', 'TVOC', 'CO2', 'HCHO'],
  datasets: [{
    data: [
      parseFloat(latestReading?.pm10) || 0,
      parseFloat(latestReading?.pm2_5) || 0,
      parseFloat(latestReading?.oxygen) || 0,
      parseFloat(latestReading?.altitude) || 0,
      parseFloat(latestReading?.ppmco2) || 0,
      parseFloat(latestReading?.ppmhcho) || 0,
    ],
    backgroundColor: [
      determineCategory(parseFloat(latestReading?.pm10) || 0, 'pm10').color,
      determineCategory(parseFloat(latestReading?.pm2_5) || 0, 'pm2_5').color,
      determineCategory(parseFloat(latestReading?.oxygen) || 0, 'oxygen').color,
      determineCategory(parseFloat(latestReading?.tvoc) || 0, 'tvoc').color,
      determineCategory(parseFloat(latestReading?.ppmco2) || 0, 'co2').color,
      determineCategory(parseFloat(latestReading?.ppmhcho) || 0, 'hcho').color,
    ],
    borderColor: '#333',
    borderWidth: 1,
  }]
});

// Memoisasi WorkshopGraph
const WorkshopGraph = React.memo(({ title, data, workshopId, onNavigate }) => {
  const chartData = useMemo(() => generateBarData(data[0]), [data]);
  
  const barOptions = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      easing: 'easeInOutQuart'
    },
    hover: {
      animationDuration: 1000
    },
    responsiveAnimationDuration: 1000,
    plugins: {
      legend: { display: false },
      title: { display: false },
      tooltip: {
        callbacks: {
          label: function(context) {
            const value = context.raw;
            const type = ['pm10', 'pm2_5', 'oxygen', 'tvoc', 'co2', 'hcho'][context.dataIndex];
            const category = determineCategory(value, type);
            return `${value}: ${category.category}`;
          }
        }
      }
    },
    scales: {
      x: { display: true },
      y: { 
        display: true,
        beginAtZero: true,
        ticks: {
          beginAtZero: true,
          stepSize: Math.ceil(10 / 5)
        }
      },
    },
  }), []);

  return (
    <div 
      style={{...styles.workshopGraph, cursor: 'pointer'}}
      onClick={() => onNavigate(workshopId)}
    >
      <h2 style={styles.graphTitle}>{title}</h2>
      <h3 style={styles.graphSubtitle}>Indeks Pencemaran Udara</h3>
      <div style={styles.chartContainer}>
        <CategoryIndicators />
        <div style={{ width: '400px', height: '250px' }}>
          {data.length > 0 ? (
            <Bar data={chartData} options={barOptions} />
          ) : (
            <p>Loading data...</p>
          )}
        </div>
      </div>
    </div>
  );
});

const Dashboard = () => {
  const navigate = useNavigate();
  const { workshopData, setWorkshopData } = useWorkshopData();

  // Fetch data setiap 5 menit
  useEffect(() => {
    const fetchData = () => {
      const db = getDatabase(app);
      
      Object.entries(WORKSHOP_IDS).forEach(([workshop, id]) => {
        const readingsRef = ref(db, `UsersData/${id}/readings`);
        
        get(readingsRef).then((snapshot) => {
          const data = snapshot.val();
          if (data) {
            const readingsArray = Object.keys(data).map((key) => ({
              timestamp: new Date(parseInt(key)).toLocaleString(),
              ...data[key],
            }));
            setWorkshopData(prev => ({
              ...prev,
              [workshop]: readingsArray.reverse()
            }));
          }
        }).catch((error) => {
          console.error(`Error fetching data for ${workshop}:`, error);
        });
      });
    };

    // Fetch initial data
    fetchData();

    // Set interval untuk update data setiap 5 menit
    const dataInterval = setInterval(fetchData, 5 * 60 * 1000);
    
    return () => clearInterval(dataInterval);
  }, [setWorkshopData]);

  const handleNavigate = (workshopId) => {
    navigate(`/workshop/${workshopId}`);
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <img src={logo} alt="Logo" style={styles.logo} />
        <h1 style={styles.title}>AIR QUALITY POLIFURNEKA</h1>
        <Clock />
      </header>

      <div style={styles.graphContainer}>
        <div style={styles.graphRow}>
          <WorkshopGraph 
            title="Workshop Area ke-1" 
            data={workshopData.workshop1} 
            workshopId="workshop1"
            onNavigate={handleNavigate}
          />
          <WorkshopGraph 
            title="Workshop Area ke-2" 
            data={workshopData.workshop2} 
            workshopId="workshop2"
            onNavigate={handleNavigate}
          />
        </div>
        <div style={styles.graphRow}>
          <WorkshopGraph 
            title="Workshop Area ke-3" 
            data={workshopData.workshop3} 
            workshopId="workshop3"
            onNavigate={handleNavigate}
          />
          <WorkshopGraph 
            title="Workshop Area ke-4" 
            data={workshopData.workshop4} 
            workshopId="workshop4"
            onNavigate={handleNavigate}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;