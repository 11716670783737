import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useWorkshopData } from '../context/WorkshopContext';
import { Bar, Line } from 'react-chartjs-2';
import Header from './Header';

import { useState } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AIR_QUALITY_THRESHOLDS = {
  pm10: {
    dangerous: 300,
    veryUnhealthy: 200,
    unhealthy: 150,
    moderate: 100,
    good: 0
  },
  pm2_5: {
    dangerous: 250,
    veryUnhealthy: 150,
    unhealthy: 100,
    moderate: 50,
    good: 0
  },
  oxygen: {
    dangerous: { min: 0, max: 19.5 },
    unhealthy: { min: 19.5, max: 20.5 },
    good: { min: 20.5, max: 21.5 },
    high: { min: 21.5, max: 100 }
  },
  tvoc: {
    dangerous: 2200,
    veryUnhealthy: 660,
    unhealthy: 220,
    moderate: 110,
    good: 0
  },
  co2: {
    dangerous: 5000,
    veryUnhealthy: 2000,
    unhealthy: 1000,
    moderate: 600,
    good: 0
  },
  hcho: {
    dangerous: 1,
    veryUnhealthy: 0.5,
    unhealthy: 0.1,
    moderate: 0.05,
    good: 0
  }
};

const CategoryIndicators = () => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '200px',
    marginRight: '20px',
  }}>
    <div style={{ backgroundColor: '#000000', padding: '5px', margin: '2px', color: 'white', borderRadius: '5px', fontSize: '12px' }}>Berbahaya</div>
    <div style={{ backgroundColor: '#FF0000', padding: '5px', margin: '2px', color: 'white', borderRadius: '5px', fontSize: '12px' }}>Sangat Tidak Sehat</div>
    <div style={{ backgroundColor: '#FFA500', padding: '5px', margin: '2px', color: 'black', borderRadius: '5px', fontSize: '12px' }}>Tidak Sehat</div>
    <div style={{ backgroundColor: '#FFFF00', padding: '5px', margin: '2px', color: 'black', borderRadius: '5px', fontSize: '12px' }}>Sedang</div>
    <div style={{ backgroundColor: '#00FF00', padding: '5px', margin: '2px', color: 'black', borderRadius: '5px', fontSize: '12px' }}>Baik</div>
  </div>
);

const determineCategory = (value, type) => {
  const thresholds = AIR_QUALITY_THRESHOLDS[type];

  if (type === 'oxygen') {
    if (value < thresholds.dangerous.max) return { category: "Berbahaya - Oksigen Rendah", color: "#000000" };
    if (value < thresholds.unhealthy.max) return { category: "Tidak Sehat", color: "#FFA500" };
    if (value < thresholds.good.max) return { category: "Baik", color: "#00FF00" };
    return { category: "Tinggi", color: "#FF0000" };
  }

  if (value >= thresholds.dangerous) return { category: "Berbahaya", color: "#000000" };
  if (value >= thresholds.veryUnhealthy) return { category: "Sangat Tidak Sehat", color: "#FF0000" };
  if (value >= thresholds.unhealthy) return { category: "Tidak Sehat", color: "#FFA500" };
  if (value >= thresholds.moderate) return { category: "Sedang", color: "#FFFF00" };
  return { category: "Baik", color: "#00FF00" };
};

const WorkshopDetail = () => {
  const { workshopData } = useWorkshopData();
  const { workshopId } = useParams();
  const navigate = useNavigate();

  const [ispuDate, setIspuDate] = useState("2023-08-15"); // Tanggal default sebagai contoh
  const ispuValue = 271; // Contoh nilai ISPU
  const ispuCategory = "SANGAT TIDAK SEHAT"; // Kategori ISPU berdasarkan nilai

  const handleDateChange = (event) => {
    setIspuDate(event.target.value);
    // Lakukan aksi tambahan jika diperlukan saat tanggal berubah, seperti fetch data historis
  };

  const determineIspuColor = (value) => {
    if (value >= 300) return '#8B0000'; // Berbahaya
    if (value >= 200) return '#FF0000'; // Sangat Tidak Sehat
    if (value >= 150) return '#FFA500'; // Tidak Sehat
    if (value >= 100) return '#FFFF00'; // Sedang
    return '#00FF00'; // Baik
  };

  const data = workshopData[workshopId] || [];
  const latestData = data[0] || {};

  const styles = {
    container: {
      padding: '20px',
      backgroundColor: '#1e2130',
      color: 'white',
    },
    content: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr 1fr',
      gap: '20px',
    },
    card: {
      backgroundColor: '#2c3246',
      borderRadius: '8px',
      padding: '15px',
      marginBottom: '20px',
    },
    cardTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '10px',
      textAlign: 'center',
    },
    sectionTitle: {
      fontSize: '24px',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '20px',
    },
    chartContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '300px',
    },
  };

  const airQualityData = {
    labels: ['PM10', 'PM2.5', 'O2', 'TVOC', 'CO2', 'HCHO'],
    datasets: [{
      data: [
        latestData.pm10,
        latestData.pm2_5,
        latestData.oxygen,
        latestData.tvoc,
        latestData.co2,
        latestData.hcho,
      ],
      backgroundColor: [
        determineCategory(latestData.pm10, 'pm10').color,
        determineCategory(latestData.pm2_5, 'pm2_5').color,
        determineCategory(latestData.oxygen, 'oxygen').color,
        determineCategory(latestData.tvoc, 'tvoc').color,
        determineCategory(latestData.co2, 'co2').color,
        determineCategory(latestData.hcho, 'hcho').color,
      ],
      borderColor: '#333',
      borderWidth: 1,
    }]
  };

  const createLineData = (parameter) => ({
    labels: data.slice(0, 24).map(d => new Date(d.timestamp).toLocaleTimeString()).reverse(),
    datasets: [{
      label: parameter.toUpperCase(),
      data: data.slice(0, 24).map(d => d[parameter.toLowerCase()]).reverse(),
      borderColor: getColorForParameter(parameter),
      tension: 0.1
    }]
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            const type = ['pm10', 'pm2_5', 'oxygen', 'tvoc', 'co2', 'hcho'][context.dataIndex];
            const category = determineCategory(value, type);
            return `${value}: ${category.category}`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: { color: 'white' },
        grid: { color: 'rgba(255, 255, 255, 0.1)' }
      },
      x: {
        ticks: { color: 'white' },
        grid: { color: 'rgba(255, 255, 255, 0.1)' }
      }
    }
  };

  function getColorForParameter(parameter) {
    const colors = {
      'pm2_5': '#f28e2c',
      'o2': '#4e79a7',
      'hcho': '#e15759',
      'tvoc': '#76b7b2',
      'co2': '#59a14f'
    };
    return colors[parameter.toLowerCase()] || '#000000';
  }



  return (
    <div style={styles.container}>
      <Header />

      <div style={{ ...styles.content, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '20px' }}>
        {/* Kolom Pertama */}
        <div>
          <h2 style={styles.sectionTitle}>WORKSHOP AREA KE - {workshopId.replace('workshop', '')}</h2>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Indeks Standar Pencemar Udara</h3>
            <div style={styles.chartContainer}>
              <CategoryIndicators />
              <div style={{ flex: 1, height: '100%' }}>
                <Bar data={airQualityData} options={chartOptions} />
              </div>
            </div>
          </div>

          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Data Konsentrasi (μg/m³)</h3>
            <div style={styles.chartContainer}>
              <Bar data={airQualityData} options={chartOptions} />
            </div>
          </div>
        </div>

        {/* Kolom Kedua */}
        <div>
          <h2 style={styles.sectionTitle}>KUALITAS UDARA</h2>
          <div style={styles.container}>

            {/* Isi Kolom Kedua - Informasi dan Dropdown Tanggal */}
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', marginBottom: '20px' }}>
              {/* Waktu ISPU */}
              <div style={{
                backgroundColor: '#1e2130',
                padding: '20px',
                borderRadius: '10px',
                textAlign: 'center',
                color: 'white',
              }}>
                <h3 style={{ fontSize: '14px', marginBottom: '10px' }}>Waktu ISPU</h3>
                <select
                  value={ispuDate}
                  onChange={handleDateChange}
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#FFD700',
                    backgroundColor: '#1e2130',
                    border: 'none',
                    textAlign: 'center',
                    cursor: 'pointer',
                    width: '100%',
                    outline: 'none',
                  }}
                >
                  <option value="2023-08-15">15 Agustus 2023</option>
                  <option value="2023-08-14">14 Agustus 2023</option>
                  <option value="2023-08-13">13 Agustus 2023</option>
                  <option value="2023-08-12">12 Agustus 2023</option>
                </select>
              </div>

              {/* Parameter Kritis */}
              <div style={{
                backgroundColor: '#1e2130',
                padding: '20px',
                borderRadius: '10px',
                textAlign: 'center',
                color: 'white'
              }}>
                <h3 style={{ fontSize: '14px', marginBottom: '10px' }}>Parameter Kritis</h3>
                <p style={{ fontSize: '24px', color: '#FFD700', fontWeight: 'bold' }}>PM<sub>2.5</sub></p>
                <img src="path/to/icon.png" alt="Icon" style={{ width: '40px', height: '40px' }} />
              </div>

              {/* Hasil ISPU */}
              <div style={{
                backgroundColor: '#1e2130',
                padding: '20px',
                borderRadius: '10px',
                textAlign: 'center',
                color: 'white'
              }}>
                <div style={{
                  width: '80px',
                  height: '80px',
                  borderRadius: '50%',
                  backgroundColor: determineIspuColor(ispuValue),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: 'auto',
                  fontSize: '24px',
                  color: 'white',
                  fontWeight: 'bold'
                }}>
                  {ispuValue}
                </div>
                <p style={{ fontSize: '14px', marginTop: '10px', fontWeight: 'bold' }}>{ispuCategory}</p>
              </div>

              {/* Keterangan */}
              <div style={{
                backgroundColor: '#1e2130',
                padding: '20px',
                borderRadius: '10px',
                textAlign: 'center',
                color: 'white'
              }}>
                <p style={{ fontSize: '12px' }}>Tingkat kualitas udara yang dapat meningkatkan resiko kesehatan pada sejumlah segmen populasi yang terpapar.</p>
              </div>
            </div>

            {/* Grafik PM2.5 (24 jam) */}
            <div style={styles.card}>
              <h3 style={styles.cardTitle}>Grafik PM2.5 (24 jam)</h3>

              {/* Box Statistik Min, Max, Avg untuk PM2.5 */}
              {(() => {
                const pm25Values = data.slice(0, 24).map(d => d.pm2_5).filter(value => typeof value === 'number' && !isNaN(value));
                const maxPM25 = Math.max(...pm25Values);
                const minPM25 = Math.min(...pm25Values);
                const avgPM25 = (pm25Values.reduce((sum, val) => sum + val, 0) / pm25Values.length).toFixed(2);

                return (
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '10px' }}>
                    <div style={{ padding: '8px', backgroundColor: '#333', color: 'white', borderRadius: '5px', minWidth: '80px', textAlign: 'center' }}>
                      Max: {maxPM25}
                    </div>
                    <div style={{ padding: '8px', backgroundColor: '#333', color: 'white', borderRadius: '5px', minWidth: '80px', textAlign: 'center' }}>
                      Min: {minPM25}
                    </div>
                    <div style={{ padding: '8px', backgroundColor: '#333', color: 'white', borderRadius: '5px', minWidth: '80px', textAlign: 'center' }}>
                      Avg: {avgPM25}
                    </div>
                  </div>
                );
              })()}

              <div style={styles.chartContainer}>
                <Bar
                  data={{
                    labels: data.slice(0, 24).map((_, i) => `${i + 1}:00`),
                    datasets: [{
                      label: 'PM2.5',
                      data: data.slice(0, 24).map(d => d.pm2_5),
                      backgroundColor: '#FFA500',
                      borderColor: '#333',
                      borderWidth: 1
                    }]
                  }}
                  options={chartOptions}
                />
              </div>
            </div>

            {/* Grafik Konsentrasi PM2.5 */}
            <div style={styles.card}>
              <h3 style={styles.cardTitle}>Konsentrasi PM2.5 (24 jam)</h3>

              {/* Box Statistik Min, Max, Avg untuk Konsentrasi PM2.5 */}
              {(() => {
                const pm25Values = data.slice(0, 24).map(d => d.pm2_5).filter(value => typeof value === 'number' && !isNaN(value));
                const maxPM25 = Math.max(...pm25Values);
                const minPM25 = Math.min(...pm25Values);
                const avgPM25 = (pm25Values.reduce((sum, val) => sum + val, 0) / pm25Values.length).toFixed(2);

                return (
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '10px' }}>
                    <div style={{ padding: '8px', backgroundColor: '#333', color: 'white', borderRadius: '5px', minWidth: '80px', textAlign: 'center' }}>
                      Max: {maxPM25}
                    </div>
                    <div style={{ padding: '8px', backgroundColor: '#333', color: 'white', borderRadius: '5px', minWidth: '80px', textAlign: 'center' }}>
                      Min: {minPM25}
                    </div>
                    <div style={{ padding: '8px', backgroundColor: '#333', color: 'white', borderRadius: '5px', minWidth: '80px', textAlign: 'center' }}>
                      Avg: {avgPM25}
                    </div>
                  </div>
                );
              })()}

              <div style={styles.chartContainer}>
                <Line
                  data={{
                    labels: data.slice(0, 24).map((_, i) => `${i + 1}:00`),
                    datasets: [{
                      label: 'Konsentrasi PM2.5 (µg/m³)',
                      data: data.slice(0, 24).map(d => d.pm2_5),
                      borderColor: '#FFA500',
                      backgroundColor: 'rgba(255, 165, 0, 0.2)',
                      tension: 0.1
                    }]
                  }}
                  options={chartOptions}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Kolom Ketiga */}
        <div>
          <h2 style={styles.sectionTitle}>CUACA</h2>
          {/* Informasi Cuaca */}
          <div style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginBottom: '20px',
            padding: '10px',
            backgroundColor: '#1e2130',
            borderRadius: '10px',
          }}>
            {/* Suhu */}
            <div style={{
              textAlign: 'center',
              color: 'white',
              padding: '10px',
              backgroundColor: '#2c3246',
              borderRadius: '8px',
              minWidth: '80px',
            }}>
              <h3 style={{ fontSize: '12px', marginBottom: '5px' }}>Suhu</h3>
              <p style={{ fontSize: '20px', color: '#FFD700', fontWeight: 'bold', margin: '0' }}>
                {latestData.temperature}
              </p>
              <p style={{ fontSize: '10px', color: '#FFD700', margin: '0' }}>°C</p>
            </div>

            {/* Kelembaban */}
            <div style={{
              textAlign: 'center',
              color: 'white',
              padding: '10px',
              backgroundColor: '#2c3246',
              borderRadius: '8px',
              minWidth: '80px',
            }}>
              <h3 style={{ fontSize: '12px', marginBottom: '5px' }}>Kelembaban</h3>
              <p style={{ fontSize: '20px', color: '#FFD700', fontWeight: 'bold', margin: '0' }}>
                {latestData.humidity}
              </p>
              <p style={{ fontSize: '10px', color: '#FFD700', margin: '0' }}>%</p>
            </div>

            {/* Tekanan Udara */}
            <div style={{
              textAlign: 'center',
              color: 'white',
              padding: '10px',
              backgroundColor: '#2c3246',
              borderRadius: '8px',
              minWidth: '80px',
            }}>
              <h3 style={{ fontSize: '12px', marginBottom: '5px' }}>Tekanan Udara</h3>
              <p style={{ fontSize: '20px', color: '#FFD700', fontWeight: 'bold', margin: '0' }}>
                {latestData.pressure}
              </p>
              <p style={{ fontSize: '10px', color: '#FFD700', margin: '0' }}>mBar</p>
            </div>
          </div>

          {/* Grafik Garis Oksigen */}
          <div style={{ ...styles.card, overflowX: 'auto', paddingBottom: '10px' }}>
            <h3 style={styles.cardTitle}>O2</h3>
            <div style={{ width: '100%' }}>
              <Line data={{
                labels: data.slice(0, 24).map((_, i) => `${i + 1}:00`),
                datasets: [{
                  label: 'O2 (%)',
                  data: data.slice(0, 24).map(d => d.oxygen),
                  borderColor: '#4e79a7',
                  backgroundColor: 'rgba(78, 121, 167, 0.2)',
                  tension: 0.1
                }]
              }} options={chartOptions} />
            </div>
          </div>

          {/* Grafik HCHO */}
          <div style={{ ...styles.card, overflowX: 'auto', paddingBottom: '10px' }}>
            <h3 style={styles.cardTitle}>HCHO (µg/m³)</h3>
            <div style={{ width: '100%' }}>
              <Line data={{
                labels: data.slice(0, 24).map((_, i) => `${i + 1}:00`),
                datasets: [{
                  label: 'HCHO (µg/m³)',
                  data: data.slice(0, 24).map(d => d.hcho),
                  borderColor: '#e15759',
                  backgroundColor: 'rgba(225, 87, 89, 0.2)',
                  tension: 0.1
                }]
              }} options={chartOptions} />
            </div>
          </div>

          {/* Grafik TVOC (Altitude) */}
          <div style={{ ...styles.card, overflowX: 'auto', paddingBottom: '10px' }}>
            <h3 style={styles.cardTitle}>TVOC</h3>
            <div style={{ width: '100%' }}>
              <Line data={{
                labels: data.slice(0, 24).map((_, i) => `${i + 1}:00`),
                datasets: [{
                  label: 'TVOC (Altitude)',
                  data: data.slice(0, 24).map(d => d.altitude),
                  borderColor: '#76b7b2',
                  backgroundColor: 'rgba(118, 183, 178, 0.2)',
                  tension: 0.1
                }]
              }} options={chartOptions} />
            </div>
          </div>

          {/* Grafik CO2 */}
          <div style={{ ...styles.card, overflowX: 'auto', paddingBottom: '10px' }}>
            <h3 style={styles.cardTitle}>CO2 (ppm)</h3>
            <div style={{ width: '100%' }}>
              <Line data={{
                labels: data.slice(0, 24).map((_, i) => `${i + 1}:00`),
                datasets: [{
                  label: 'CO2 (ppm)',
                  data: data.slice(0, 24).map(d => d.co2),
                  borderColor: '#59a14f',
                  backgroundColor: 'rgba(89, 161, 79, 0.2)',
                  tension: 0.1
                }]
              }} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopDetail;