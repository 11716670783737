import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import WorkshopDetail from './components/WorkshopDetail';
import { WorkshopProvider } from './context/WorkshopContext';

function App() {
  return (
    <WorkshopProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/workshop/:workshopId" element={<WorkshopDetail />} />
        </Routes>
      </Router>
    </WorkshopProvider>
  );
}

export default App;