import React, { createContext, useState, useContext } from 'react';

const WorkshopContext = createContext();

export const WorkshopProvider = ({ children }) => {
  const [workshopData, setWorkshopData] = useState({
    workshop1: [],
    workshop2: [],
    workshop3: [],
    workshop4: []
  });

  return (
    <WorkshopContext.Provider value={{ workshopData, setWorkshopData }}>
      {children}
    </WorkshopContext.Provider>
  );
};

export const useWorkshopData = () => {
  const context = useContext(WorkshopContext);
  if (!context) {
    throw new Error('useWorkshopData must be used within a WorkshopProvider');
  }
  return context;
};