import React, { useState, useEffect } from 'react';
import logo from '../assets/logo_univ.png';

const Header = () => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
      setBlink((prev) => !prev);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const styles = {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#383c54',
      color: 'white',
      padding: '10px 20px',
      borderRadius: '5px',
      marginBottom: '20px',
    },
    logo: {
      width: '50px',
      height: '50px',
      marginRight: '15px',
    },
    title: {
      fontSize: '1.5em',
      fontWeight: 'bold',
      flex: '1',
    },
    clock: {
      border: '2px solid white',
      padding: '10px',
      borderRadius: '5px',
      textAlign: 'center',
      lineHeight: '1.5',
      minWidth: '200px',
    },
  };

  return (
    <header style={styles.header}>
      <img src={logo} alt="Logo" style={styles.logo} />
      <h1 style={styles.title}>AIR QUALITY POLIFURNEKA</h1>
      <div style={styles.clock}>
        <div>Kendal, {currentDateTime.toLocaleDateString('id-ID', { year: 'numeric', month: 'long', day: 'numeric' })}</div>
        <div style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
          {`${currentDateTime.getHours().toString().padStart(2, '0')}${blink ? ':' : ' '}${currentDateTime.getMinutes().toString().padStart(2, '0')}`} WIB
        </div>
      </div>
    </header>
  );
};

export default Header;